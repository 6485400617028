import {
  Transaction,
  TransactionDocument,
  FieldStatus,
  ErrorField,
  INSCRIPTION_TYPES
} from '@inpi-marques/components'
import InternalReferenceField from 'component/internalReference/InternalReferenceField'
import Suite from 'component/suite/Suite'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { storeTransactionInitDeposit, storeTransactionUpdateInscription } from 'store/transaction/transactionActions'
import SubProcedureTypeItem from '../../../deposit/form/depositType/SubProcedureTypeItem'

interface InscriptionTypesProps {
    transaction: Transaction,
    fieldStatus: FieldStatus,
    documents: TransactionDocument[]
}
const InscriptionTypes: FC<InscriptionTypesProps> = ({ transaction, fieldStatus, documents }) => {
  const dispatch = useDispatch()

  /**
     * Au clic sur un type de dépôt, on l'initialise dans le store
     * @param type
     */
  const onInscriptionTypeClick = (type: string) => {
    if (transaction.subProcedureType !== type) {
      dispatch(storeTransactionInitDeposit(type))
      dispatch(storeTransactionUpdateInscription({
        ...transaction.inscription,
        natureCodes: []
      }))
    }
  }

  return (
    <div className='inscription-type'>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='inscription_type_title' /></h1>
          <span className='subtitle'><FormattedMessage id='inscription_type_description' /></span>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <div className='justify-content-center col-10'>
        {
          INSCRIPTION_TYPES
            .map((inscriptionConstant, index) => (
              <SubProcedureTypeItem
                value={inscriptionConstant.value}
                key={index}
                label={inscriptionConstant.label}
                onClick={(value) => onInscriptionTypeClick(value)}
                className='md-6 mb-3 p-0'
                active={inscriptionConstant.value === transaction.subProcedureType}
                fieldStatus={fieldStatus}
                documents={documents}
              />
            ))
        }

      </div>
      {fieldStatus.type && (<div className='col-12'> <ErrorField message={fieldStatus.type} className='fade alert alert-danger show position-relative mt-4' /></div>)}
      <Suite
        transaction={transaction}
        fieldStatus={fieldStatus}
      />
    </div>
  )
}

export default InscriptionTypes
