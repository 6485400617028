import React, { FC, ReactElement, useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import InternalReferenceField from '../../../internalReference/InternalReferenceField'
import {
  CardBlock,
  containsErrors,
  ErrorField,
  FieldStatus,
  ProductClass,
  Transaction,
  ProductClassVersion,
  DateUtils,
  PRODUCT_CLASS_VERSION_STATUS, StepButtonsUtils
} from '@inpi-marques/components'
import {
  DEPOSIT_PRODUCTS_AND_SERVICES_TAB_FORM,
  TAB_FORM_CLASSIFICATION_ADVANCED_SEARCH,
  TAB_FORM_CLASSIFICATION_FREE_INPUT,
  TAB_FORM_CLASSIFICATION_IMPORT_BY_FILE,
  TAB_FORM_CLASSIFICATION_OVERVIEW,
  TAB_FORM_CLASSIFICATION_YOUR_BASKET
} from '../../../../constants/ProductsAndServicesConstants'
import StoreService from '../../../../services/StoreService'
import ProductAndServicesList from './ProductsAndServiceList'
import ProductsService from 'services/deposit/ProductsService'
import ProductAndServicesFileImport from './ProductAndServicesFileImport'
import ProductAndServicesCart from './ProductAndServiceCart'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { storeTransactionFieldStatusReset } from 'store/fieldStatus/fieldStatusActions'
import { PRODUCT_AND_SERVICE_VERSION_TYPE } from '@inpi-marques/components/src/constants/DepositConstants'
import TransactionService from '../../../../services/transaction/TransactionService'
import ProductAndServicesFreeInput from './ProductAndServicesFreeinput'

interface ProductsAndServicesProps {
  transaction: Transaction
  fieldStatus: FieldStatus
}
export interface IAllProducts {
  [key: string]: ProductClass[]
}

export interface ICurrentSearchValues {
  [key: string]: string
}

/**
 * Formulaire parent des produits et service d'un dépôt
 */
const ProductsAndServices: FC<ProductsAndServicesProps> = ({
  transaction, children, fieldStatus
}) => {
  const dispatch = useDispatch()

  // Onglet sélectionné
  const [selectedTab, setSelectedTab] = useState(DEPOSIT_PRODUCTS_AND_SERVICES_TAB_FORM[0])
  const guidedMode = useSelector((state : RootStateOrAny) => state?.transaction?.guidedMode)

  // Version de dépôt (Version initiale)
  const [productClassVersion, setProductClassVersion] = useState<ProductClassVersion>(TransactionService.getProductClassVersionByName(
    PRODUCT_AND_SERVICE_VERSION_TYPE.INITIAL_VERSION,
      transaction.deposit?.productsAndServicesVersions || []
  ) ?? { status: PRODUCT_CLASS_VERSION_STATUS.ACCEPTED, type: PRODUCT_AND_SERVICE_VERSION_TYPE.INITIAL_VERSION, productClasses: [], createdDate: DateUtils.now() })

  // On garde dans ce state la liste des produits & services récupérés afin de garder le contenu quand on change d'onglets
  const [allProducts, setAllProducts] = useState<IAllProducts>({ nice: [], tmclass: [] })

  // Valeurs des inputs de Nice et TMClass
  const [currentSearchValues, setCurrentSearchValues] = useState<ICurrentSearchValues>({ nice: '', tmclass: '' })

  const productsAndServicesRef = useRef()
  const stepButtonsRef = useRef()

  /**
   * A la modification de la liste des produits et services par un des composant enfant
   * @param productClasses
   */
  const onProductClassesEdited = (productClasses: ProductClass[]): void => {
    setProductClassVersion({ ...productClassVersion, productClasses: productClasses })
    if (containsErrors(fieldStatus)) {
      dispatch(storeTransactionFieldStatusReset())
    }
  }
  /**
   * Au premier chargement, on récupère toutes les classes Nice
   */
  useEffect(() => {
    (async () => {
      const products: ProductClass[] = await ProductsService.searchProducts('nice')
      setAllProducts({ nice: products, tmclass: [] })
    })()

    const { observer, move } = StepButtonsUtils.initStepButtonsListener(productsAndServicesRef, stepButtonsRef)

    return () => {
      window.removeEventListener('scroll', move)
      observer.disconnect()
    }
  }, [])

  /**
   * Lorsque l'on modifie une/des classes, on l'enregistre dans le store
   */
  useEffect(() => {
    // On enregistre dans le store
    StoreService.changeStore(
      {
        ...transaction,
        deposit: {
          ...transaction.deposit,
          productsAndServicesVersions: [{
            ...productClassVersion,
            productClasses: ProductsService.sortProductClassByRef(productClassVersion.productClasses ?? [])
          }]
        },
        overviewConsented: false
      })
  }, [productClassVersion.productClasses])

  useEffect(() => {
    StepButtonsUtils.moveStepButtons(productsAndServicesRef, stepButtonsRef)
  }, [selectedTab, productClassVersion, productsAndServicesRef])

  /**
   * On met à jour le boutons 'Etape suivante' du formulaire du StepContainer.
   * Si l'utilisateur appuie sur ce bouton alors qu'il n'est pas dans le panier, alors il y est redirigé
   *
   * Pour le moment, children n'est pas volumineux (cf StepContainer.tsx L 225), mais s'il venait à grandir, il faudra faire attention à ce
   * que cela n'engendre pas des problèmes de perf.
   *
   * @param children
   * @returns
   */
  const getEditedChildren = (children: ReactElement): ReactElement[] => {
    return React.Children.map(children, (child: ReactElement) => {
      // On check si l'élément est valide ou non
      if (React.isValidElement(child)) {
        // On réassigne child afin de pas avoir à le caster à chaque utilisation
        const validatedChild: ReactElement = child
        const updatedChildProps: {children: ReactElement[], onClick?: () => void} = { children: getEditedChildren(validatedChild.props.children) }
        if (validatedChild.props.id === 'stepform-next-button' && selectedTab !== TAB_FORM_CLASSIFICATION_YOUR_BASKET) {
          updatedChildProps.onClick = () => setSelectedTab(TAB_FORM_CLASSIFICATION_YOUR_BASKET)
        }
        // On retourne le clone de l'enfant mis à jour (ou non)
        return React.cloneElement(child, updatedChildProps)
      }
      return child
    })
  }

  return (
    <>
      <div className='products-and-services' ref={productsAndServicesRef}>
        <div className='row mb-4 justify-content-between'>
          <header className='col-8'>
            <h1 className='products-and-services__title'><FormattedMessage id='products_and_services_title' /></h1>
          </header>
          <InternalReferenceField
            transaction={transaction}
            className='col-3'
          />
        </div>
        <div className='products-and-services__tabs row'>
          {
            DEPOSIT_PRODUCTS_AND_SERVICES_TAB_FORM.map((tab, index) => (
              <div className='col' key={`tab-${tab}`}>
                <CardBlock
                  shadow
                  onClick={() => setSelectedTab(tab)}
                  className={`products-and-services__tab ${selectedTab === tab ? 'border-primary text-primary' : ''}`}
                  bodyClassName='px-3 py-2 text-uppercase justify-content-center align-items-center text-center'
                >
                  <div className='products-and-services__tab-content'>
                    <FormattedMessage id={`products_and_services_tab_${tab.toLowerCase()}`} />
                    {(index === DEPOSIT_PRODUCTS_AND_SERVICES_TAB_FORM.length - 1 && productClassVersion.productClasses && productClassVersion.productClasses.length > 0) &&
                      <span>  ({ProductsService.countProducts(productClassVersion.productClasses)})</span>}
                  </div>
                </CardBlock>
              </div>
            ))
          }
        </div>
        {
          (selectedTab === TAB_FORM_CLASSIFICATION_OVERVIEW || selectedTab === TAB_FORM_CLASSIFICATION_ADVANCED_SEARCH) &&
            <ProductAndServicesList
              productClassesSelected={productClassVersion.productClasses ?? []}
              setProductClassesSelected={onProductClassesEdited}
              type={selectedTab === TAB_FORM_CLASSIFICATION_OVERVIEW ? 'nice' : 'tmclass'}
              allProducts={allProducts}
              setAllProducts={setAllProducts}
              currentSearchValues={currentSearchValues}
              setCurrentSearchValues={setCurrentSearchValues}
            />
        }
        {
          (selectedTab === TAB_FORM_CLASSIFICATION_FREE_INPUT &&
            <ProductAndServicesFreeInput
              productClasses={productClassVersion.productClasses ?? []}
              onProductClassesEdited={onProductClassesEdited}
              isInitialVersion
              procedureType={transaction.procedureType}
              guidedMode={guidedMode.activeGuidedMode}
            />
          )
        }
        {
          (selectedTab === TAB_FORM_CLASSIFICATION_IMPORT_BY_FILE &&
            <ProductAndServicesFileImport
              productClasses={productClassVersion.productClasses ?? []}
              onProductClassesEdited={onProductClassesEdited}
              procedureType={transaction.procedureType}
            />
          )
        }
        {
          (selectedTab === TAB_FORM_CLASSIFICATION_YOUR_BASKET &&
            <ProductAndServicesCart
              productClasses={productClassVersion.productClasses ?? []}
              onProductClassesEdited={onProductClassesEdited}
            />
          )
        }
        {
          fieldStatus.productsAndServices &&
            <div className='col-12'> <ErrorField message={fieldStatus.productsAndServices} className='fade alert alert-danger show position-relative mt-4' /></div>
        }
        <div className='step-buttons--fixed' ref={stepButtonsRef}>
          {getEditedChildren(children as ReactElement)}
        </div>
      </div>
    </>
  )
}

export default ProductsAndServices
