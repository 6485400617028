import React, { FC, useEffect, useState } from 'react'
import {
  TransactionDocument,
  EventType,
  FilAriane,
  Transaction,
  DEPOSIT_CONTRIBUTORS_TYPES,
  Deposit,
  ContributorValidator,
  FieldStatus,
  DEFAULT_CONTRIBUTOR,
  ModalComponent,
  EditorialBlock,
  PROCEDURE_DEPOSIT,
  DEPOSIT_CONTRIBUTORS_OPTIONS, containsErrors, Contributor,
  PERSONNE_PHYSIQUE, PERSONNE_MORALE, EMPTY_CONTRIBUTOR,
  CONTRIBUTOR_GUIDED_ENTITY_FORMATION,
  CONTRIBUTOR_GUIDED_PM,
  CONTRIBUTOR_GUIDED_VARIOUS_ACCOUNTS,
  CommonContributorService, CONTRIBUTOR_DEPOSITORS
} from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import StepContainer from '../../stepper/StepContainer'
import DepositTypes from './depositType/DepositTypes'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import DepositTypeValidator from './depositType/validator/DepositTypeValidator'
import ContributorFrontForm from '../../contributors/ContributorFrontForm'
import {
  storeTransactionInit, storeTransactionRemove,
  storeTransactionUpdate
} from 'store/transaction/transactionActions'
import { storeTransactionFieldStatusReset } from 'store/fieldStatus/fieldStatusActions'
import TransactionService from 'services/transaction/TransactionService'
import ContributorValidatorAndSaveStore from '../../../services/contributors/ContributorValidatorAndSaveStore'
import ContributorAgent from '../../contributors/ContributorAgent'
import ContributorList from '../../contributors/ContributorList'
import BrandType from './brandType/BrandType'
import BrandTypeValidator from './brandType/validator/BrandTypeValidator'
import ContributorService from '../../../services/contributors/ContributorService'
import ProductsAndServices from './productsAndServices/ProductsAndServices'
import Options from './options/Options'
import OverviewValidator from '../../recap/validator/OverviewValidator'
import ContributorGuide from '../../contributors/ContributorGuide'
import ProductsAndServicesValidator from './productsAndServices/validator/ProductsAndServicesValidator'
import PaymentForm from 'component/payment/PaymentForm'
import { PAYMENT_LABELS, PAYMENT_OBJECTS, PAYMENT_URL_DEPOSIT } from 'constants/PaymentConstants'
import ContentService from '../../../services/content/ContentService'
import { PRODUCT_AND_SERVICE_VERSION_TYPE } from '@inpi-marques/components/src/constants/DepositConstants'
import Recap from '../../recap/Recap'
import PrioritiesValidator from '../../priorities/validator/PrioritiesValidator'
import StoreService from '../../../services/StoreService'
import ConfigurationService from '../../../services/configuration/ConfigurationService'
/* global localStorage */

const DepositForm: FC<RouteComponentProps> = ({
  history,
  match,
  isNewTransaction = false
}) => {
  const dispatch = useDispatch()
  const [showRgpd, setShowRgpd] = useState(false)
  const [rgpdContent, setRdpdContent] = useState('')
  const fieldStatus: FieldStatus = useSelector((state : RootStateOrAny) => state.transactionFieldStatus)
  const transaction: Transaction = useSelector((state : RootStateOrAny) => state.transaction)
  const deposit: Deposit = TransactionService.getDeposit(transaction) ?? {}
  const user = useSelector((state : RootStateOrAny) => state.user.user)
  const guidedMode = useSelector((state : RootStateOrAny) => state?.transaction?.guidedMode)
  const valueSwitchIntervenant = useSelector((state : RootStateOrAny) => state?.transaction?.guidedMode?.choiceContributor)
  const [documents, setDocuments] = useState<TransactionDocument[]>(transaction.documents ?? [])
  const [isNew, setIsNew] = useState<boolean>(isNewTransaction)
  const [required, setIsRequired] = useState<boolean>(false)

  // Gestion de l'étape suivante pour les options
  const [editIndex, setEditIndex] = useState<number>(-1)

  // Gestion de l'affichage du formulaire / de la liste des déposants au premier affichage
  const [depositorsOpenOnFirstOne, setDepositorsOpenOnFirstOne] = useState<boolean>(true)

  const handleSubmit = () => {
    // Si la dernière étape est valide, on submit le formulaire
    history.push('/')
  }

  // si on a plusieurs déposants dans la transaction on n'affiche pas le mode édition du premier
  // mais la liste des déposants
  useEffect(() => {
    setDepositorsOpenOnFirstOne(!transaction?.depositors || transaction.depositors.length < 2)
  }, [transaction.depositors])

  useEffect(() => {
    setDocuments(transaction.documents ?? [])
  }, [transaction.documents])

  useEffect(() => {
    // Initialisation du dépôt à modifier
    if (!isNewTransaction) {
      TransactionService.getTransaction(match.params.id).then(result => {
        dispatch(storeTransactionUpdate(result))
        setDocuments(result.documents)
      })
      setDepositorsOpenOnFirstOne(false)
    } else {
      dispatch(storeTransactionInit(PROCEDURE_DEPOSIT.value))
      // Texte d'information sur le RGPD affiché en popin
      ContentService.getEditorialBlockByCode('RGPD').then((res: EditorialBlock) => {
        if (res.content) {
          setRdpdContent(res.content)
          setShowRgpd(true)
        }
      })
    }
  }, [isNewTransaction])

  useEffect(() => {
    ConfigurationService.getConfigurationsValues(['ELECTRONIC_NOTIF']).then(result =>
      localStorage.setItem('ELECTRONIC_NOTIF', result?.[0].value)
    )

    return () => {
      dispatch(storeTransactionRemove())
    }
  }, [])

  /**
   * Lorsque le type de dépôt change, on reset le contenu lié au type
   */
  useEffect(() => {
    dispatch(storeTransactionFieldStatusReset())
  }, [transaction.subProcedureType])

  const onDocumentChanged = (event: EventType, type: string) => {
    const { value } = event.target

    const newFile: TransactionDocument = {
      type: type,
      internalName: value.lastModified.toString(),
      name: value.name,
      format: value.type,
      file: value
    }
    setDocuments([...documents, newFile])
  }

  const onDocumentDeleted = (documentToDelete: TransactionDocument|EventType) => {
    const internalName = documentToDelete?.target?.value ? documentToDelete.target.value.internalName : documentToDelete?.internalName
    setDocuments(documents.filter((file: TransactionDocument) => file.internalName !== internalName))
  }

  const saveDepositType = async () => {
    if (isNew && !transaction?.id) {
      setIsNew(false)
      return await TransactionService.createTransaction(transaction, PROCEDURE_DEPOSIT.value, ['deposit', 'subProcedureType', 'guidedMode'], documents)
    } else {
      return await TransactionService.updateTransactionBDDFromStore(transaction, ['deposit', 'subProcedureType', 'guidedMode'], documents)
    }
  }

  /**
   * En fonction de la valeur du bouton switch intervenant, je paramètre l'onglet Déposant
   */
  const stepGuide = async () => {
    let newTransaction: Transaction | undefined

    const depositors: Contributor[] = [...(transaction.depositors ?? [{ address: { country: 'FR' } }])]

    switch (valueSwitchIntervenant) {
      case CONTRIBUTOR_GUIDED_ENTITY_FORMATION.idBtnSwitch:
        newTransaction = {
          ...transaction,
          depositors: depositors.map((depositor: Contributor) => ({
            ...depositor,
            personFormation: true
          }))
        }
        break
      case CONTRIBUTOR_GUIDED_PM.idBtnSwitch:
        newTransaction = {
          ...transaction,
          depositors: depositors.map((depositor: Contributor) => ({
            ...depositor,
            lastname: undefined,
            firstname: undefined,
            civilityCode: undefined,
            type: PERSONNE_MORALE.value
          }))
        }
        break
      default:
        newTransaction = {
          ...transaction,
          depositors: depositors.map((depositor: Contributor) => ({
            ...depositor,
            companyName: undefined,
            legalForm: undefined,
            siren: undefined,
            type: PERSONNE_PHYSIQUE.value
          }))
        }
        break
    }
    if (newTransaction) {
      return await TransactionService.updateTransactionBDDFromStore(newTransaction, ['depositors'], documents)
    } else {
      return true
    }
  }

  /**
   * Edition de la liste des déposants
   * @returns
   */
  const onDepositorEdited = async (): Promise<Transaction|null> => {
    // Supprime les déposants non conformes
    let newContributorList : Contributor[] = []
    transaction.depositors.map((contributor) => {
      const fieldStatusContributor = ContributorValidator.validateContributor(contributor, transaction, CONTRIBUTOR_DEPOSITORS.value)
      if (!containsErrors(fieldStatusContributor)) {
        newContributorList = [...newContributorList, contributor]
      }
    })

    depositorsOpenOnFirstOne && setDepositorsOpenOnFirstOne(false)

    // Dans le cas où un mandataire avait été saisi, puis le mode guidé a changé, il faut supprimer le mandataire
    let updatedTransaction = { ...transaction, depositors: newContributorList }
    let properties = ['guidedMode', DEPOSIT_CONTRIBUTORS_TYPES.DEPOSITORS.value]
    if (guidedMode?.activeGuidedMode &&
      valueSwitchIntervenant !== CONTRIBUTOR_GUIDED_VARIOUS_ACCOUNTS.idBtnSwitch &&
      transaction?.depositors?.length < 2 &&
      transaction?.agent) {
      updatedTransaction = { ...updatedTransaction, agent: EMPTY_CONTRIBUTOR }
      properties = [...properties, DEPOSIT_CONTRIBUTORS_TYPES.AGENT.value]
      StoreService.changeStore({ agent: null })
    }
    return await TransactionService.updateTransactionBDDFromStore(updatedTransaction, properties)
  }

  return (
    <>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <span><FormattedMessage id='breadcrumb_new_depot' /></span>
      </FilAriane>

      <StepContainer
        className='mb-5 is-validated'
        listTitle={<FormattedMessage id='depot_step_list_title' />}
        textBtnSwitch={<FormattedMessage id='button_guide' />}
        onCancel={() => history.push('/')}
        onSubmit={handleSubmit}
        required={required}
        setIsRequired={setIsRequired}
        views={[
          {
            id: 'deposit_type',
            label: <FormattedMessage id='select_depot_type_title' />,
            hasGuidedSwitch: true,
            onGoToStep: saveDepositType,
            component:
  <DepositTypes
    deposit={deposit}
    onDocumentChanged={onDocumentChanged}
    onDocumentDeleted={onDocumentDeleted}
    fieldStatus={fieldStatus}
    transaction={transaction}
    documents={documents}
    setDocuments={setDocuments}
  />,
            validateGoToStep: () => DepositTypeValidator.validateOnChangeStep(documents, transaction.subProcedureType, TransactionService.getDeposit(transaction))
          },
          {
            id: 'contributors',
            label: <FormattedMessage id='contributor_title' />,
            hasGuidedSwitch: true,
            component: guidedMode?.activeGuidedMode && <ContributorGuide transaction={transaction} fieldStatus={fieldStatus} />,
            onGoToStep: stepGuide,
            validateGoToStep: () => ContributorValidatorAndSaveStore.validateOnChangeStepGuidedMode(guidedMode?.activeGuidedMode, guidedMode?.choiceContributor)
          },
          {
            id: 'contributors_depositors',
            label: <FormattedMessage id='contributor_depositors' />,
            level: 1,
            hasGuidedSwitch: true,
            component: (
              <ContributorList
                openOnFirstOne={depositorsOpenOnFirstOne}
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={DEPOSIT_CONTRIBUTORS_TYPES.DEPOSITORS.value}
                contributorTypeToCopy={[
                  DEPOSIT_CONTRIBUTORS_TYPES.AGENT.value,
                  DEPOSIT_CONTRIBUTORS_TYPES.RECIPIENT.value,
                  DEPOSIT_CONTRIBUTORS_TYPES.SIGNATORY.value
                ]}
                optionForm={DEPOSIT_CONTRIBUTORS_OPTIONS.depositors}
                procedure={PROCEDURE_DEPOSIT.value}
                validateContributor={ContributorValidator.validateContributor}
                activeGuidedMode={guidedMode?.activeGuidedMode}
                choiceContributor={valueSwitchIntervenant}
                setIsRequired={setIsRequired}
              />
            ),
            stepButtonsAsChildren: true,
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepDepositorsDeposit,
            onGoToStep: () => onDepositorEdited()
          },
          {
            condition: valueSwitchIntervenant === CONTRIBUTOR_GUIDED_VARIOUS_ACCOUNTS.idBtnSwitch || guidedMode?.activeGuidedMode === false || transaction?.depositors?.length > 1,
            id: 'contributors_agent',
            label: <FormattedMessage id='contributor_agent' />,
            level: 1,
            hasGuidedSwitch: true,
            component: (
              <ContributorAgent
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorTypeToCopy={[
                  DEPOSIT_CONTRIBUTORS_TYPES.DEPOSITORS.value,
                  DEPOSIT_CONTRIBUTORS_TYPES.RECIPIENT.value,
                  DEPOSIT_CONTRIBUTORS_TYPES.SIGNATORY.value
                ]}
                procedure={PROCEDURE_DEPOSIT.value}
                setIsRequired={setIsRequired}
                activeGuidedMode={guidedMode?.activeGuidedMode}
                choiceContributor={valueSwitchIntervenant}
              />),
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepAgentDeposit,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, [DEPOSIT_CONTRIBUTORS_TYPES.AGENT.value])
              .then((transaction: Transaction|null) => {
                if (transaction) {
                  return ContributorService.updateDocumentFromContributor(transaction, DEPOSIT_CONTRIBUTORS_TYPES.AGENT.value, documents, setDocuments)
                }
              })
          },
          {
            id: 'contributors_recipient',
            label: <FormattedMessage id='contributor_recipient' />,
            level: 1,
            hasGuidedSwitch: true,
            component: (
              <ContributorFrontForm
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={DEPOSIT_CONTRIBUTORS_TYPES.RECIPIENT.value}
                contributorTypeToCopy={[
                  DEPOSIT_CONTRIBUTORS_TYPES.DEPOSITORS.value,
                  DEPOSIT_CONTRIBUTORS_TYPES.AGENT.value,
                  DEPOSIT_CONTRIBUTORS_TYPES.SIGNATORY.value
                ]}
                optionForm={DEPOSIT_CONTRIBUTORS_OPTIONS.recipient}
                procedure={PROCEDURE_DEPOSIT.value}
                defaultContributor={{ ...DEFAULT_CONTRIBUTOR, email: user.email, consentNotificationByEmail: user.consentNotificationByEmailPreferences }}
                setIsRequired={setIsRequired}
                activeGuidedMode={guidedMode?.activeGuidedMode}
                choiceContributor={valueSwitchIntervenant}
              />),
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepRecipient,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore({
              ...transaction,
              [DEPOSIT_CONTRIBUTORS_TYPES.RECIPIENT.value]: CommonContributorService.filterContributorCivilityCode(transaction[DEPOSIT_CONTRIBUTORS_TYPES.RECIPIENT.value] ?? {})
            }, [DEPOSIT_CONTRIBUTORS_TYPES.RECIPIENT.value, DEPOSIT_CONTRIBUTORS_TYPES.AGENT.value])
          },
          {
            id: 'contributors_signatory',
            label: <FormattedMessage id='contributor_signatory' />,
            level: 1,
            hasGuidedSwitch: true,
            component: (
              <ContributorFrontForm
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={DEPOSIT_CONTRIBUTORS_TYPES.SIGNATORY.value}
                optionForm={DEPOSIT_CONTRIBUTORS_OPTIONS.signatory}
                procedure={PROCEDURE_DEPOSIT.value}
                defaultContributor={transaction?.signatory ?? ContributorService.buildDefaultSignatoryFromUser(user)}
                actionWithAddressBook={false}
                setIsRequired={setIsRequired}
                activeGuidedMode={guidedMode?.activeGuidedMode}
                choiceContributor={valueSwitchIntervenant}
              />),
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepSignatoryDeposit,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, [DEPOSIT_CONTRIBUTORS_TYPES.SIGNATORY.value])
              .then((transaction: Transaction|null) => {
                if (transaction) {
                  return ContributorService.updateDocumentFromContributor(transaction, DEPOSIT_CONTRIBUTORS_TYPES.SIGNATORY.value, documents, setDocuments)
                }
              })
          },
          {
            id: 'brand_type',
            label: <FormattedMessage id='brand_type_title_isListing' />,
            hasGuidedSwitch: true,
            component:
  <BrandType
    transaction={transaction}
    deposit={deposit}
    fieldStatus={fieldStatus}
    onDocumentDeleted={onDocumentDeleted}
    onDocumentChanged={onDocumentChanged}
    documents={documents}
    setIsRequired={setIsRequired}
  />,
            validateGoToStep: () => BrandTypeValidator.validateOnChangeStep(deposit, documents),
            onGoToStep: async () => TransactionService.updateTransactionBDDFromStore(transaction, ['deposit'], documents),
            stepButtonsAsChildren: true
          },
          {
            id: 'products_and_services',
            label: <FormattedMessage id='products_and_services_title' />,
            hasGuidedSwitch: true,
            component: <ProductsAndServices fieldStatus={fieldStatus} transaction={transaction} />,
            validateGoToStep: () => ProductsAndServicesValidator.validateOnChangeStep(
              TransactionService.getProductClassVersionByName(PRODUCT_AND_SERVICE_VERSION_TYPE.INITIAL_VERSION, deposit.productsAndServicesVersions || [])
            ),
            onGoToStep: async () => TransactionService.updateTransactionBDDFromStore(transaction, ['deposit']),
            stepButtonsAsChildren: true
          },
          {
            id: 'options',
            label: <FormattedMessage id='options_label' />,
            component: (
              <Options
                transaction={transaction}
                documents={documents}
                setDocuments={setDocuments}
                onDocumentDeleted={onDocumentDeleted}
                editIndex={editIndex}
                setEditIndex={setEditIndex}
                fieldStatus={fieldStatus}
                setIsRequired={setIsRequired}
              />),
            validateGoToStep: () => PrioritiesValidator.validateOnChangeStep(editIndex, transaction),
            onGoToStep: async () => TransactionService.updateTransactionBDDFromStore(transaction, ['deposit'], documents)
          },
          {
            id: 'overview',
            label: <FormattedMessage id='overview_title' />,
            component: <Recap fieldStatus={fieldStatus} transaction={transaction} />,
            validateGoToStep: () => OverviewValidator.validateOnChangeStep(transaction)
          },
          {
            id: 'payment',
            noStepButtons: true,
            label: <FormattedMessage id='payment_title' />,
            component: (
              <PaymentForm
                transaction={transaction}
                label={PAYMENT_LABELS.INITIAL}
                objectName={PAYMENT_OBJECTS.TRANSACTION}
                urlType={PAYMENT_URL_DEPOSIT}
              />
            )
          }]}
      />
      <ModalComponent
        title={<FormattedMessage id='rgpd_title' />}
        isNotCancellable
        customContent={() => <div dangerouslySetInnerHTML={{ __html: rgpdContent }} />}
        handleClose={() => setShowRgpd(false)}
        show={showRgpd}
        onClick={() => setShowRgpd(false)}
        customButtonId='common_start_form'
        size='xl'
      />
    </>
  )
}

export default withRouter(DepositForm)
