import moment from 'moment'
import Message from '../constants/Message'
import { Contributor } from '../interfaces/contributors/ContributorsInterfaces'
/* global localStorage */

export function isFilled (value: string|any[]|null|undefined): boolean {
  return (!!value && value.length > 0)
}

export function messageIsFilled (value: string|any[]|null|undefined): string {
  if (!isFilled(value)) {
    return Message.required_field
  }
  return ''
}

export function boolIsFilled (value: boolean | undefined): string {
  if (value === undefined) {
    return Message.required_field
  }
  return ''
}

export function messageIsNumberValid (value: string): string {
  if (!isNumberValid(value)) {
    return Message.invalid_number
  }
  return ''
}

export function validateStartDateRange (firstDate: string|null|undefined, secondDate: string|null|undefined): string {
  if (!firstDate) {
    return Message.required_field
  }
  if (secondDate && moment(firstDate) > moment(secondDate)) {
    return Message.validation_start_date
  }
  return ''
}

export function validateEndDateRange (firstDate: string|null|undefined, secondDate: string|null|undefined): string {
  if (!secondDate) {
    return ''
  }
  if (moment(secondDate) < moment(firstDate)) {
    return Message.validation_end_date
  }
  return ''
}

export function validateSamePassword (newPassword: string|null|undefined, newPasswordCheck: string|null|undefined): string {
  if (!isFilled(newPasswordCheck)) {
    return Message.required_field
  }

  if (newPassword !== newPasswordCheck) {
    return Message.invalid_password_check
  }
  return ''
}

const REGEX_CODE_POSTAL = /^((0[1-9])|([1-8][0-9])|(9[0-8]))[0-9]{3}$/
export function isCodePostalValid (value: string): boolean {
  return REGEX_CODE_POSTAL.test(value)
}

const REGEX_CODE_COMMUNE = /^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B))[0-9]{3}$/
export function isCodeCommuneValid (value: string): boolean {
  return REGEX_CODE_COMMUNE.test(value)
}

const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export function isMailFormatValid (value: string): boolean {
  return REGEX_EMAIL.test(value)
}

const REGEX_NUMBER = /^[0-9]*$/
export function isNumberValid (value: string): boolean {
  return REGEX_NUMBER.test(value)
}

const REGEX_CUT_DOCUMENT = /^[0-9-, ]*$/
export function isCuttingFormatValid (value: string): boolean {
  return REGEX_CUT_DOCUMENT.test(value)
}

const REGEX_TELEPHONE = /^(?:(?:\+|00)33|(?:\+|00)262|(?:\+|00)590|(?:\+|00)594|(?:\+|00)596|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/
export function isTelephoneValid (value: string): boolean {
  return REGEX_TELEPHONE.test(value)
}

/**
 * Valide une date au format ISO
 */
export function isDateFormatValid (value: string): boolean {
  return moment(value, 'YYYY-MM-DD', true).isValid()
}

/**
 * Valide que l'année sois supérieure a la min'
 */
export function isMinDateValid (value: number): string {
  if (value < 1900) {
    return Message.validation_min_date
  }
  return ''
}

const REGEX_SIREN = /^[0-9]{9}$/
export function isNumeroSiren (value: string): boolean {
  return REGEX_SIREN.test(value)
}

/**
 * Permet de verifier si le siren de l'intervenant en paramètre est bien pour une personne morale de droit public
 */
export function isSirenPublic (contributor: Contributor): boolean {
  // Si c'est une personne morale de droit public le siren doit commencé par 1 ou 2
  return !(contributor.siren && contributor.publicLaw && contributor.siren[0] !== '1' && contributor.siren[0] !== '2')
}

/**
 * On vérifie par l'algorithme de Luhn la validité du siren
 * @param siren
 */
export function isSirenAlgoLuhnValid (siren: string) : boolean {
  const len = siren.length
  const parity = len % 2
  let sum = 0
  for (let i = len - 1; i >= 0; i--) {
    let d = parseInt(siren.charAt(i))
    if (i % 2 === parity) { d *= 2 }
    if (d > 9) { d -= 9 }
    sum += d
  }
  return (sum % 10) === 0
}

export function isTextLatin (value: string): boolean {
  const regexLatin: string = localStorage.getItem('REGEX_LATIN')

  if (regexLatin) {
    try {
      return new RegExp(regexLatin).test(value)
    } catch (e) {
      return true
    }
  }

  return true
}

/** Emojis */
export const REGEX_EMOJI = /\p{Extended_Pictographic}/u
/** Drapeaux + Keycaps */
export const REGEX_OTHERS = /([\uD83C][\uDDE6-\uDDFF])|(\u20e3)/g

/**
 * Si la chaine de caractère possède des émoji, ou d'autres caractère spéciaux
 * @param value
 * @returns
 */
export const hasEmoticons = (value?: string): boolean =>
  !!(value && REGEX_EMOJI.test(value)) || !!(value && REGEX_OTHERS.test(value))
