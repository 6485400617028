import React, { FC, useEffect, useState } from 'react'
import {
  containsErrors,
  Contributor,
  CONTRIBUTOR_DEPOSITORS,
  ContributorValidator,
  DEFAULT_CONTRIBUTOR,
  DEPOSIT_CONTRIBUTORS_TYPES,
  EditorialBlock,
  FieldStatus,
  FilAriane,
  INSCRIPTION_CONTRIBUTORS_OPTIONS,
  INSCRIPTION_CONTRIBUTORS_TYPES,
  INSCRIPTION_TYPE_ACT_TITLE,
  INSCRIPTION_TYPE_AGENT,
  INSCRIPTION_TYPE_CORRECTION,
  INSCRIPTION_TYPE_REWARD,
  ModalComponent,
  NatureCode,
  PROCEDURE_INSCRIPTION,
  Transaction,
  TransactionDocument
} from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import StepContainer from '../../stepper/StepContainer'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import {
  storeTransactionInit,
  storeTransactionRemove,
  storeTransactionUpdate
} from 'store/transaction/transactionActions'
import TransactionService from 'services/transaction/TransactionService'
import ContentService from '../../../services/content/ContentService'
import InscriptionTypeValidator from './incriptionType/validator/InscriptionTypeValidator'
import InscriptionTypes from './incriptionType/InscriptionTypes'
import ContributorList from '../../contributors/ContributorList'
import ContributorValidatorAndSaveStore from '../../../services/contributors/ContributorValidatorAndSaveStore'
import ContributorAgent from '../../contributors/ContributorAgent'
import ContributorService from '../../../services/contributors/ContributorService'
import ContributorFrontForm from '../../contributors/ContributorFrontForm'
import Recap from '../../recap/Recap'
import OverviewValidator from '../../recap/validator/OverviewValidator'
import CorrectionForm from './correction/CorrectionForm'
import CorrectionFormValidator from './correction/validator/CorrectionFormValidator'
import TitlesValidator from './titles/validator/TitlesValidator'
import ActTitleForm from './act/ActTitleForm'
import ActTitleFormValidator from './act/validator/ActTitleFormValidator'
import ContestForm from './contest/ContestForm'
import ContestFormValidator from './contest/validator/ContestFormValidator'
import MandataryForm from './mandatary/MandataryForm'
import MandataryFormValidator from './mandatary/validator/MandataryFormValidator'
import PaymentForm from '../../payment/PaymentForm'
import { PAYMENT_LABELS, PAYMENT_OBJECTS, PAYMENT_URL_INSCRIPTION } from '../../../constants/PaymentConstants'
import Titles from './titles/Titles'
import AgentAttachment from './mandatary/AgentAttachment'
import { toast } from 'react-toastify'
import Message from '../../../constants/Message'
import ConfigurationService from '../../../services/configuration/ConfigurationService'
/* global localStorage */

const InscriptionForm: FC<RouteComponentProps> = ({
  history,
  match,
  isNewTransaction = false
}) => {
  const dispatch = useDispatch()
  const [showRgpd, setShowRgpd] = useState(false)
  const [rgpdContent, setRdpdContent] = useState('')
  const fieldStatus: FieldStatus = useSelector((state: RootStateOrAny) => state.transactionFieldStatus)
  const transaction: Transaction = useSelector((state: RootStateOrAny) => state.transaction)
  const [documents, setDocuments] = useState<TransactionDocument[]>(transaction.documents ?? [])
  const [isNew, setIsNew] = useState<boolean>(isNewTransaction)
  const user = useSelector((state: RootStateOrAny) => state.user.user)
  const [required, setIsRequired] = useState<boolean>(false)
  /** Code natures récupérées du Back */
  const [natureCodes, setNatureCodes] = useState<NatureCode[]>([])

  useEffect(() => {
    if (transaction.subProcedureType === INSCRIPTION_TYPE_CORRECTION.value) {
      ContentService.getNatureCodesByType(transaction.subProcedureType).then((response: NatureCode[]) => {
        setNatureCodes(response)
      })
    }
  }, [transaction.subProcedureType])

  const handleSubmit = () => {
    // Si la dernière étape est valide, on submit le formulaire
    history.push('/')
  }

  const onDocumentEdited = (editedDocument: TransactionDocument): void => {
    setDocuments([...documents.map((document: TransactionDocument) =>
      document.name === editedDocument.name && document.createdAt === editedDocument.createdAt
        ? editedDocument : document
    )])
  }

  const onDocumentAdded = (document: TransactionDocument) => {
    // Ne pas prendre en compte le fichier s'il est déjà présent dans la liste
    if (documents.some((occurence) => occurence.name === document.name)) {
      toast.error(Message.error_transaction_document_add)
      return
    }
    setDocuments([...documents, document])
  }

  const onDocumentDeleted = (deletedDocument: TransactionDocument) => {
    setDocuments(documents.filter((file: TransactionDocument) =>
      !(deletedDocument.createdAt === file.createdAt && deletedDocument.internalName === file.internalName && deletedDocument.filename === file.filename)))
  }

  const resetContributorByType = (): Transaction => {
    return {
      ...transaction,
      organisers: transaction.subProcedureType !== INSCRIPTION_TYPE_REWARD.value ? [] : transaction.organisers,
      otherApplicants: transaction.subProcedureType !== INSCRIPTION_TYPE_ACT_TITLE.value ? [] : transaction.otherApplicants,
      inscription: {
        ...transaction.inscription
      }
    }
  }

  const saveInscriptionType = async () => {
    const transactionUpdated = resetContributorByType()
    if (isNew && !transaction.id) {
      setIsNew(false)
      return await TransactionService.createTransaction(transactionUpdated, PROCEDURE_INSCRIPTION.value, ['subProcedureType', 'inscription', 'suite'])
    } else {
      return await TransactionService.updateTransactionBDDFromStore(transactionUpdated, ['subProcedureType', 'inscription', 'suite', INSCRIPTION_CONTRIBUTORS_TYPES.OTHER_APPLICANTS.value, INSCRIPTION_CONTRIBUTORS_TYPES.ORGANISERS.value], documents)
    }
  }

  /**
     * Edition de la liste des déposants
     * @returns
     */
  const onDepositorsEdited = async (): Promise<Transaction|null> => {
    // Supprime les déposants non conformes
    const newContributorList : Contributor[] = transaction.depositors ? transaction.depositors.filter((contributor) =>
      !containsErrors(ContributorValidator.validateContributor(contributor, transaction, CONTRIBUTOR_DEPOSITORS.value)))
      : []
    return await TransactionService.updateTransactionBDDFromStore({ ...transaction, depositors: newContributorList }, [DEPOSIT_CONTRIBUTORS_TYPES.DEPOSITORS.value])
  }

  useEffect(() => {
    // Initialisation du dépôt à modifier
    if (!isNewTransaction) {
      TransactionService.getTransaction(match.params.id).then(result => {
        dispatch(storeTransactionUpdate(result))
        setDocuments(result.documents)
      })
    } else {
      dispatch(storeTransactionInit(PROCEDURE_INSCRIPTION.value))
      // Texte d'information sur le RGPD affiché en popin
      ContentService.getEditorialBlockByCode('RGPD-INSCRIPTION').then((res: EditorialBlock) => {
        if (res.content) {
          setRdpdContent(res.content)
          setShowRgpd(true)
        }
      })
    }
  }, [isNewTransaction])

  useEffect(() => {
    ConfigurationService.getConfigurationsValues(['ELECTRONIC_NOTIF']).then(result =>
      localStorage.setItem('ELECTRONIC_NOTIF', result?.[0].value)
    )

    return () => {
      dispatch(storeTransactionRemove())
    }
  }, [])

  useEffect(() => {
    setDocuments(transaction.documents ?? [])
  }, [transaction.documents])

  return (
    <>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <span><FormattedMessage id='select_inscription_title' /></span>
      </FilAriane>

      <StepContainer
        className='mb-5 is-validated'
        listTitle={<FormattedMessage id='select_inscription_title' />}
        onCancel={() => history.push('/')}
        onSubmit={handleSubmit}
        required={required}
        setIsRequired={setIsRequired}
        views={[
          {
            id: 'inscription_type',
            label: <FormattedMessage id='inscription_type_title' />,
            onGoToStep: saveInscriptionType,
            component: <InscriptionTypes fieldStatus={fieldStatus} transaction={transaction} documents={documents} />,
            validateGoToStep: () => InscriptionTypeValidator.validateOnChangeStep(transaction, fieldStatus)
          },
          {
            id: 'inscription_titles',
            label: <FormattedMessage id='inscription_brand_title' />,
            condition: transaction.subProcedureType !== INSCRIPTION_TYPE_REWARD.value,
            onGoToStep: async () => TransactionService.updateTransactionBDDFromStore(transaction, ['inscription'], documents),
            component: (
              <Titles
                fieldStatus={fieldStatus}
                transaction={transaction}
                showDownloadExampleFile
                showImportTitleFile
                showDownloadTitleList
              />),
            validateGoToStep: () => TitlesValidator.validateOnChangeStep(transaction)
          },
          {
            id: 'contributors',
            label: <FormattedMessage id='contributor_title' />
          },
          {
            id: 'contributors_depositors',
            label: <FormattedMessage id='contributor_depositors_inscription' />,
            level: 1,
            component: (
              <ContributorList
                openOnFirstOne={!transaction.depositors?.length}
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={INSCRIPTION_CONTRIBUTORS_TYPES.DEPOSITORS.value}
                contributorTypeLabel={`${INSCRIPTION_CONTRIBUTORS_TYPES.DEPOSITORS.value}_inscription`}
                contributorTypeToCopy={[
                  INSCRIPTION_CONTRIBUTORS_TYPES.OTHER_APPLICANTS.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.AGENT.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.RECIPIENT.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.SIGNATORY.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.ORGANISERS.value
                ]}
                externalContributorsToCopy={transaction?.inscription?.titles?.length === 1 ? transaction.inscription?.titles[0].holders : []}
                optionForm={INSCRIPTION_CONTRIBUTORS_OPTIONS.depositors}
                procedure={PROCEDURE_INSCRIPTION.value}
                validateContributor={ContributorValidator.validateContributor}
                setIsRequired={setIsRequired}
              />
            ),
            stepButtonsAsChildren: true,
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepDepositorsDeposit,
            onGoToStep: onDepositorsEdited
          },
          {
            id: 'contributors_otherApplicants',
            label: <FormattedMessage id='contributor_otherApplicants' />,
            level: 1,
            condition: transaction.subProcedureType === INSCRIPTION_TYPE_ACT_TITLE.value,
            component: (
              <ContributorList
                openOnFirstOne={!transaction.otherApplicants?.length}
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={INSCRIPTION_CONTRIBUTORS_TYPES.OTHER_APPLICANTS.value}
                contributorTypeLabel={INSCRIPTION_CONTRIBUTORS_TYPES.OTHER_APPLICANTS.value}
                contributorTypeToCopy={[
                  INSCRIPTION_CONTRIBUTORS_TYPES.DEPOSITORS.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.AGENT.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.RECIPIENT.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.SIGNATORY.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.ORGANISERS.value
                ]}
                externalContributorsToCopy={transaction?.inscription?.titles?.length === 1 ? transaction.inscription?.titles[0].holders : []}
                optionForm={INSCRIPTION_CONTRIBUTORS_OPTIONS.otherApplicants}
                procedure={PROCEDURE_INSCRIPTION.value}
                validateContributor={ContributorValidator.validateContributor}
                setIsRequired={setIsRequired}
              />
            ),
            stepButtonsAsChildren: true,
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepOtherApplicants,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, [INSCRIPTION_CONTRIBUTORS_TYPES.OTHER_APPLICANTS.value, 'inscription'])
          },
          {
            id: 'contributors_organisers',
            label: <FormattedMessage id='contributor_organisers' />,
            level: 1,
            condition: transaction.subProcedureType === INSCRIPTION_TYPE_REWARD.value,
            component: (
              <ContributorList
                openOnFirstOne={!transaction.organisers?.length}
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={INSCRIPTION_CONTRIBUTORS_TYPES.ORGANISERS.value}
                contributorTypeLabel={INSCRIPTION_CONTRIBUTORS_TYPES.ORGANISERS.value}
                contributorTypeToCopy={[
                  INSCRIPTION_CONTRIBUTORS_TYPES.DEPOSITORS.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.OTHER_APPLICANTS.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.AGENT.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.RECIPIENT.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.SIGNATORY.value
                ]}
                optionForm={INSCRIPTION_CONTRIBUTORS_OPTIONS.organisers}
                procedure={PROCEDURE_INSCRIPTION.value}
                validateContributor={ContributorValidator.validateContributor}
                setIsRequired={setIsRequired}
              />
            ),
            stepButtonsAsChildren: true,
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepOrganisers,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, [INSCRIPTION_CONTRIBUTORS_TYPES.ORGANISERS.value, 'inscription'])
          },
          {
            id: 'contributors_agent',
            label: <FormattedMessage id='contributor_agent' />,
            level: 1,
            component: (
              <ContributorAgent
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorTypeToCopy={[
                  INSCRIPTION_CONTRIBUTORS_TYPES.DEPOSITORS.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.RECIPIENT.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.SIGNATORY.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.OTHER_APPLICANTS.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.ORGANISERS.value
                ]}
                option={INSCRIPTION_CONTRIBUTORS_OPTIONS.agent}
                procedure={PROCEDURE_INSCRIPTION.value}
                setIsRequired={setIsRequired}
              />),
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepAgentDeposit,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, [INSCRIPTION_CONTRIBUTORS_TYPES.AGENT.value, 'inscription'])
              .then((transaction: Transaction | null) => {
                if (transaction) {
                  return ContributorService.updateDocumentFromContributor(transaction, INSCRIPTION_CONTRIBUTORS_TYPES.AGENT.value, documents, setDocuments)
                }
              })
          },
          {
            id: 'contributors_recipient',
            label: <FormattedMessage id='contributor_recipient' />,
            level: 1,
            component: (
              <ContributorFrontForm
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={INSCRIPTION_CONTRIBUTORS_TYPES.RECIPIENT.value}
                contributorTypeToCopy={[
                  INSCRIPTION_CONTRIBUTORS_TYPES.DEPOSITORS.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.AGENT.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.SIGNATORY.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.OTHER_APPLICANTS.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.ORGANISERS.value
                ]}
                optionForm={INSCRIPTION_CONTRIBUTORS_OPTIONS.recipient}
                procedure={PROCEDURE_INSCRIPTION.value}
                defaultContributor={{
                  ...DEFAULT_CONTRIBUTOR,
                  email: user.email,
                  consentNotificationByEmail: user.consentNotificationByEmailPreferences
                }}
                setIsRequired={setIsRequired}
              />),
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepRecipient,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, [INSCRIPTION_CONTRIBUTORS_TYPES.RECIPIENT.value, 'inscription'])
          },
          {
            id: 'contributors_signatory',
            label: <FormattedMessage id='contributor_signatory' />,
            level: 1,
            component: (
              <ContributorFrontForm
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={INSCRIPTION_CONTRIBUTORS_TYPES.SIGNATORY.value}
                optionForm={INSCRIPTION_CONTRIBUTORS_OPTIONS.signatory}
                procedure={PROCEDURE_INSCRIPTION.value}
                defaultContributor={ContributorService.buildDefaultSignatoryFromUser(user)}
                actionWithAddressBook={false}
                setIsRequired={setIsRequired}
              />),
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepSignatoryDeposit,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, [INSCRIPTION_CONTRIBUTORS_TYPES.SIGNATORY.value, 'inscription'])
              .then((transaction: Transaction|null) => {
                if (transaction) {
                  return ContributorService.updateDocumentFromContributor(transaction, INSCRIPTION_CONTRIBUTORS_TYPES.SIGNATORY.value, documents, setDocuments)
                }
              })
          },
          {
            id: 'correction',
            label: <FormattedMessage id='correction_title' />,
            condition: transaction.subProcedureType === INSCRIPTION_TYPE_CORRECTION.value,
            component: (
              <CorrectionForm
                transaction={transaction}
                fieldStatus={fieldStatus}
                onDocumentEdited={onDocumentEdited}
                onDocumentAdded={onDocumentAdded}
                onDocumentDeleted={onDocumentDeleted}
                documents={documents}
                natureCodes={natureCodes}
              />
            ),
            validateGoToStep: () => CorrectionFormValidator.validateCorrectionStep(documents, transaction.inscription, natureCodes),
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, ['inscription'], documents)
          },
          {
            id: 'act_title',
            label: <FormattedMessage id='act_title' />,
            condition: transaction.subProcedureType === INSCRIPTION_TYPE_ACT_TITLE.value,
            component: (
              <ActTitleForm
                transaction={transaction}
                fieldStatus={fieldStatus}
                onDocumentEdited={onDocumentEdited}
                onDocumentAdded={onDocumentAdded}
                onDocumentDeleted={onDocumentDeleted}
                documents={documents}
              />
            ),
            validateGoToStep: () => ActTitleFormValidator.validatActStep(documents, transaction.inscription),
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, ['inscription'], documents)
          },
          {
            id: 'contest',
            label: <FormattedMessage id='contest_title' />,
            condition: transaction.subProcedureType === INSCRIPTION_TYPE_REWARD.value,
            component: (
              <ContestForm
                transaction={transaction}
                fieldStatus={fieldStatus}
                onDocumentEdited={onDocumentEdited}
                onDocumentAdded={onDocumentAdded}
                onDocumentDeleted={onDocumentDeleted}
                documents={documents}
              />
            ),
            validateGoToStep: () => ContestFormValidator.validateContestForm(documents, transaction.inscription),
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, ['inscription'], documents)
          },
          {
            id: 'mandatary',
            label: <FormattedMessage id='mandatary_title' />,
            condition: transaction.subProcedureType === INSCRIPTION_TYPE_AGENT.value,
            component: (
              <MandataryForm
                transaction={transaction}
                fieldStatus={fieldStatus}
                onDocumentEdited={onDocumentEdited}
                onDocumentAdded={onDocumentAdded}
                onDocumentDeleted={onDocumentDeleted}
                documents={documents}
              />
            ),
            validateGoToStep: () => MandataryFormValidator.validateMandataryForm(documents, transaction.inscription),
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, ['inscription'], documents)
          },
          {
            id: 'agent_attachment',
            label: <FormattedMessage id='inscription_agent_attachment_title' />,
            condition: transaction.subProcedureType === INSCRIPTION_TYPE_AGENT.value,
            onGoToStep: async () => TransactionService.updateTransactionBDDFromStore(transaction, ['inscription'], documents),
            component: <AgentAttachment transaction={transaction} />
          },
          {
            id: 'overview',
            label: <FormattedMessage id='overview_title' />,
            component: <Recap fieldStatus={fieldStatus} transaction={transaction} />,
            validateGoToStep: () => OverviewValidator.validateOnChangeStep(transaction)
          },
          {
            id: 'payment',
            noStepButtons: true,
            label: <FormattedMessage id='payment_title' />,
            component: (
              <PaymentForm
                transaction={transaction}
                label={PAYMENT_LABELS.INSCRIPTION}
                objectName={PAYMENT_OBJECTS.TRANSACTION}
                urlType={PAYMENT_URL_INSCRIPTION}
              />
            )
          }
        ]}
      />
      <ModalComponent
        title={<FormattedMessage id='rgpd_title' />}
        isNotCancellable
        customContent={() => <div dangerouslySetInnerHTML={{ __html: rgpdContent }} />}
        handleClose={() => setShowRgpd(false)}
        show={showRgpd}
        onClick={() => setShowRgpd(false)}
        customButtonId='common_start_form'
        size='xl'
      />
    </>
  )
}

export default withRouter(InscriptionForm)
